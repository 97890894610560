.small-container {
    max-width: 500px;
    margin: 0 auto;
}

.medium-container {
    max-width: 750px;
    margin: 0 auto;
}

input[type=checkbox] {
    width: 35px;
    height: 35px;
    vertical-align: middle;
}

.action {
    margin: 0 5px;
    padding: 15px;
    border-radius: 5px;
    background-color: #ccc;
}

.quest, .quest-response {
    background-color: #ccc;
    padding: 15px;
    white-space: pre-line;
    margin: 10px 0;
}

.report {
    background-color: #ccc;
    padding: 15px;
    margin: 10px 0;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    background-color: #154775;
    color: white;
    min-height: 100vh;
    padding: 25px;
}

.menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.menu li {
    margin: 15px 0;
}

.menu li a, .menu li a:visited, .menu li a:active, .menu li a:hover, .menu li a:focus {
    color: white;
    text-decoration: none;
}

.page {
    margin-left: 300px;
    padding: 25px;
}

.chat-answer {
    background-color: #ccc;
    padding: 15px;
    margin: 15px 0;
    white-space: pre-line;
}

.close {
    font-size: 24px;
    font-weight: bold;
    color: #555;
    outline: none;
    border: none;
    background-color: transparent;
    float: right;
}

.login-box {
    padding: 25px;
    background-color: #ccc;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

